import '@/serviceChatWidget/scss/main.scss';

import App from './components/App';
import Vue from 'vue';
import store from '@/serviceChatWidget/js/store';
import Http, { initHttp } from '@/serviceChatWidget/js/helpers/Http';

function getPropsFromOriginalScript() {
  const scripts = document.getElementsByTagName('script');
  let found = false;
  let baseUrl;
  let apiToken;

  for (let i = 0; i < scripts.length; i++) {
    const script = scripts[i];
    const src = script.src;
    const m = src.match(/^((http|https):\/\/(.*?)\/)(.*)/);
    const dataApiToken = script.getAttribute('data-api-token');

    if (m !== null && !!m[4] && m[4] === 'service-chat/widget.js' && dataApiToken !== null) {
      if (found) {
        console.warn("[ServiceChat.ai] There are multiple versions of the ServiceChat.ai Widget installed on your site. Please remove all but one of them to avoid unexpected behavior.");
      }

      found = true;
      baseUrl = m[1];
      apiToken = dataApiToken;
    }
  }

  if (found) {
    return {
      baseUrl: baseUrl,
      apiToken: apiToken,
    }
  } else {
    console.warn('[ServiceChat.ai] Api token not found');
    return false;
  }
}

function runApp() {
  const id = 'service-chat-widget';
  let appEl = document.getElementById(id);
  const props = getPropsFromOriginalScript();

  if (!appEl && props !== false) {
    appEl = document.createElement('div');
    document.body.appendChild(appEl);
    appEl.id = id;

    initHttp(props.baseUrl, props.apiToken);

    new Vue({
      store,
      Http,
      el: appEl,
      render: h => h(App, {
        props: props,
      })
    });
  }
}

runApp();
