import Vue from 'vue';
import store from '@/serviceChatWidget/js/store';

export default {
  name: "IFrame",

  props: {
      baseUrl: String,
      stylesheets: {
          type: Array,
          default: [],
      },
  },

  data() {
    return {
      iframeApp: null,
    }
  },

  render(h) {
    return h('iframe', {
      on: {
        load: this.renderChildren
      }
    });
  },

  beforeUpdate() {
    this.iframeApp.children = Object.freeze(this.$slots.default);
  },

  methods: {
    renderChildren() {
      const children = this.$slots.default;
      const body = this.$el.contentDocument.body;
      const el = document.createElement('div');

      this.appendMeta();
      this.appendStyle();
      this.appendStylesheets();
      body.appendChild(el);

      const iframeApp = new Vue({
        name: 'iframeApp',
        store,
        data() {
          return {
            children: Object.freeze(children)
          }
        },
        render(h) {
          return h('div', this.children);
        }
      });

      iframeApp.$mount(el);
      this.iframeApp = iframeApp;
    },

    appendMeta() {
      const head = this.$el.contentDocument.head;

      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=device-width';
      head.appendChild(meta);
    },

    appendStylesheets() {
      const head = this.$el.contentDocument.head;

      this.stylesheets.forEach((stylesheet) => {
        const link = document.createElement('link');
        link.href = this.baseUrl + stylesheet;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        head.appendChild(link);
      });
    },

    appendStyle() {
      const head = this.$el.contentDocument.head;
      const styleText =
        '.frame-content{display:none}';

      const style = document.createElement('style');
      style.innerText = styleText;
      head.appendChild(style);
    }
  }
}
