import Http from '@/serviceChatWidget/js/helpers/Http';
import { ACTION_GET_WIDGET_DATA, MUTATION_UPDATE } from './configStoreTypes';

const state = () => ({
  accentColor: null,
});

const mutations = {
  [MUTATION_UPDATE] (state, data) {
    state.accentColor = data.accentColor;
  }
};

const actions = {
  async [ACTION_GET_WIDGET_DATA] ({commit}) {
    const response = await Http.post('service-chat/get_config', {}, {});
    commit(MUTATION_UPDATE, response.data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
