<template>
    <svg aria-hidden="true" focusable="false" class="svg-icon">
        <use :xlink:href="( '#' + name )" />
    </svg>
</template>

<script>
    export default {
        name: "SvgIcon",

        props: {
            name: {
                type: String,
                default: ''
            },
        }
    }
</script>

<style lang="scss">
    .svg-icon {
        color: inherit;
        stroke: currentColor;
        fill: currentColor;
        display: block;
        height: 100% ;
        width: 100% ;
    }
</style>
