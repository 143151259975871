import Http from '@/serviceChatWidget/js/helpers/Http';
import maskString from '@/serviceChatWidget/js/helpers/maskString';
import {
  ACTION_SEND,
  MUTATION_UPDATE_FORM_DISPLAY_PHONE,
  MUTATION_UPDATE_FORM_MESSAGE,
  MUTATION_UPDATE_FORM_NAME,
  MUTATION_UPDATE_FORM_PHONE,
  MUTATION_UPDATE_SENDING,
  MUTATION_UPDATE_SENT,
  MUTATION_UPDATE_SENT_DATA,
  MUTATION_UPDATE_SENT_ERROR,
  MUTATION_UPDATE_VISIBLE
} from './chatStoreTypes';
import Vue from 'vue';

const state = () => ({
  visible: false,
  form: {
    name: {
      value: '',
      error: null,
      touched: false
    },
    phone: {
      value: '',
      error: null,
      touched: false
    },
    displayPhone: '',
    message: {
      value: '',
      error: null,
      touched: false
    },
  },
  sending: false,
  sent: false,
  sentData: {
    from: null,
    to: null,
  },
  sentError: null,
});

const mutations = {
  [MUTATION_UPDATE_VISIBLE] (state, value) {
    state.visible = value;
    if (state.visible === false && state.sent === true) {
      Vue.set(state.form, 'message', {
        value: '',
        error: '',
        touched: false
      });
      state.form.displayPhone = maskString(state.form.displayPhone, 4);
      state.sent = false;
      Vue.set(state, 'sentData', {
        from: null,
        to: null,
      });
    }
    state.sentError = null;
  },
  [MUTATION_UPDATE_FORM_NAME] (state, value) {
    Vue.set(state.form, 'name', value);
  },
  [MUTATION_UPDATE_FORM_PHONE] (state, value) {
    Vue.set(state.form, 'phone', value);
    state.form.displayPhone = value.value;
  },
  [MUTATION_UPDATE_FORM_DISPLAY_PHONE] (state, value) {
    state.form.displayPhone = value;
  },
  [MUTATION_UPDATE_FORM_MESSAGE] (state, value) {
    Vue.set(state.form, 'message', value);
  },
  [MUTATION_UPDATE_SENDING] (state, value) {
    state.sending = value;
    if (state.sending) {
      state.sentError = null;
    }
  },
  [MUTATION_UPDATE_SENT] (state, value) {
    state.sent = value;
  },
  [MUTATION_UPDATE_SENT_ERROR] (state, value) {
    state.sentError = value;
  },
  [MUTATION_UPDATE_SENT_DATA] (state, data) {
    Vue.set(state, 'sentData', {
      ...data,
      from: maskString(data['from'], 4)
    });
  },
};

const actions = {
  async [ACTION_SEND] ({commit, state}) {
    commit(MUTATION_UPDATE_SENDING, true);

    const data = {
      name: state.form.name.value,
      phone: state.form.phone.value,
      message: state.form.message.value
    };

    try {
      const response = await Http.post('service-chat/send_message', data, {});
      commit(MUTATION_UPDATE_SENT, true);
      commit(MUTATION_UPDATE_SENT_DATA, response.data);
    } catch (err) {
      if ((err.response instanceof Object) && (err.response.status >= 500)) {
        commit(MUTATION_UPDATE_SENT_ERROR, 'Sorry, chat is unavailable');
      }
    } finally {
      commit(MUTATION_UPDATE_SENDING, false);
    }
  }
};

const getters = {
  formValid: state => {
    return state.form.name.touched && !state.form.name.error &&
      state.form.phone.touched && !state.form.phone.error &&
      state.form.message.touched && !state.form.message.error;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
