<template>
    <button
        class="send-button"
        :class="{
            'send-button--loading': loading,
            'send-button--disabled': disabled
        }"
        @click="handleClick($event)"
    >
        <span class="send-button__inner">Send</span>
        <span class="loading-spinner">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none"/>
            </svg>
        </span>
    </button>
</template>

<script>
    export default {
        name: "SendButton",

        props: {
            loading: {
                type: Boolean,
                default: false
            },

            disabled: {
                type: Boolean,
                default: false
            },
        },

        methods: {
            handleClick($event) {
                if (!this.disabled) {
                    this.$emit('click', $event);
                }
            }
        }
    }
</script>

<style lang="scss">
    .send-button {
        display: block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid transparent;
        color: #ffffff;
        text-align: center;
        outline: none;
        margin: 0;
        transition: 0.1s;
        font-weight: 400;
        padding: 12px 30px;
        font-size: 16px;
        border-radius: 4px;
        position: relative;

        .send-button__inner {
            opacity: 1;
        }

        .loading-spinner {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            justify-content: center;
            align-items: center;

            .circular {
                height: 28px;
                width: 28px;
                animation: loading-rotate 2s linear infinite;
            }

            .path {
                animation: loading-dash 1.5s ease-in-out infinite;
                stroke-dasharray: 90,150;
                stroke-dashoffset: 0;
                stroke-width: 4;
                stroke: #ffffff;
                stroke-linecap: round;
            }
        }

        &.send-button--loading {
            pointer-events: none;

            .send-button__inner {
                opacity: 0;
            }

            .loading-spinner {
                display: flex;
            }
        }

        &.send-button--disabled {

            &:after {
                content: ' ';
                position: absolute;
                top: -1px;
                left: -1px;
                right: -1px;
                bottom: -1px;
                background-color: rgba(255, 255, 255, 0.6);
            }
        }
    }
</style>
