<template>
    <div class="frame-content">
        <IconsDefinition></IconsDefinition>
        <ChatButton
            :accent-color="configAccentColor"
            :opened="chatVisible"
            @click="chatButtonClick"
        ></ChatButton>
    </div>
</template>

<script>
    import IconsDefinition from '@/serviceChatWidget/js/components/IconsDefinition';
    import * as configStoreTypes from '@/serviceChatWidget/js/store/modules/config/configStoreTypes';
    import * as chatStoreTypes from '@/serviceChatWidget/js/store/modules/chat/chatStoreTypes';
    import ChatButton from './ChatButton';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: "ChatButtonFrameContent",

        components: {
            IconsDefinition,
            ChatButton
        },

        computed: {
            ...mapState(configStoreTypes.STORE_PATH, {
                configAccentColor: state => state.accentColor,
            }),
            ...mapState(chatStoreTypes.STORE_PATH, {
                chatVisible: state => state.visible,
            }),
        },

        methods: {
            ...mapMutations(chatStoreTypes.STORE_PATH, {
                chatUpdateVisible: chatStoreTypes.MUTATION_UPDATE_VISIBLE,
            }),

            chatButtonClick() {
                this.chatUpdateVisible(!this.chatVisible);
            }
        }
    }
</script>

<style lang="scss">
    .chat-button {
        animation: fadeIn .25s ease;
        position: fixed;
        right: 30px;
        bottom: 30px;
        z-index: 10000000;
    }
</style>
