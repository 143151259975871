<template>
    <div class="frame-content">
        <ChatFrameStyles :accent-color="configAccentColor"></ChatFrameStyles>
        <IconsDefinition></IconsDefinition>
        <div class="frame-content__background" @click="closeChat"></div>
        <div class="chat-container" v-if="chatVisible">
            <div class="chat" :class="{'chat--sent': chatSent}">
                <button class="chat__close-button" @click="closeChat">
                    <SvgIcon name="icon-service-chat-close"></SvgIcon>
                </button>

                <div class="chat__header" :style="{'background-color': configAccentColor}">
                    <div class="chat__title">
                        <SvgIcon name="icon-service-chat"></SvgIcon>
                        {{chatSent ? 'Received!' : 'Hello!'}}
                    </div>
                    <div class="chat__sub-title" v-if="!chatSent">
                        Welcome to ServiceChat. Start a conversation and a team member will text you back shortly.
                    </div>
                </div>
                <div class="chat__body">
                    <SendForm v-if="!chatSent"></SendForm>

                    <div class="message-info" v-if="chatSent">
                        <div class="answer-container">
                            <div class="answer-title">
                                {{chatSentData.to}}
                            </div>
                            <div class="answer-message">
                                Our team will be texting you back shortly from the number above.
                            </div>
                        </div>

                        <div class="input-message-container">
                            <div>
                                <div class="input-message">
                                    <div class="input-message-phone">{{chatSentData.from}}</div>
                                    {{chatMessage}}
                                    <SvgIcon name="icon-service-chat-ok"></SvgIcon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="chat__send-button-container" v-if="!chatSent">
                    <div>
                        <SendButton @click="sendButtonClick" :loading="chatSending" :disabled="!chatFormValid"></SendButton>
                        <div class="send-hint">
                            By submitting you agree to receive text messages at the number provided. Message/data rates apply.
                        </div>
                    </div>
                </div>
                <div class="chat__footer">
                    <span>powered by</span><SvgIcon name="icon-full-logo-grey"></SvgIcon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ChatFrameStyles from '@/serviceChatWidget/js/components/chatFrame/ChatFrameStyles';
    import SendButton from './SendButton';
    import SendForm from './SendForm';
    import IconsDefinition from '@/serviceChatWidget/js/components/IconsDefinition';
    import SvgIcon from '@/serviceChatWidget/js/components/SvgIcon';
    import * as configStoreTypes from '@/serviceChatWidget/js/store/modules/config/configStoreTypes';
    import * as chatStoreTypes from '@/serviceChatWidget/js/store/modules/chat/chatStoreTypes';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: "ChatFrameContent",

        components: {
            SendButton,
            ChatFrameStyles,
            SendForm,
            SvgIcon,
            IconsDefinition
        },

        computed: {
            ...mapState(configStoreTypes.STORE_PATH, {
                configAccentColor: state => state.accentColor,
            }),
            ...mapState(chatStoreTypes.STORE_PATH, {
                chatVisible: state => state.visible,
                chatSending: state => state.sending,
                chatSent: state => state.sent,
                chatSentData: state => state.sentData,
                chatSentError: state => state.sentError,
                chatMessage: state => state.form.message.value,
            }),
            ...mapGetters(chatStoreTypes.STORE_PATH, {
                chatFormValid: 'formValid',
            })
        },

        methods: {
            ...mapMutations(chatStoreTypes.STORE_PATH, {
                chatUpdateVisible: chatStoreTypes.MUTATION_UPDATE_VISIBLE,
            }),
            ...mapActions(chatStoreTypes.STORE_PATH, {
                chatSend: chatStoreTypes.ACTION_SEND,
            }),

            async sendButtonClick() {
                if (this.chatFormValid) {
                    await this.chatSend();
                }
            },

            closeChat() {
                this.chatUpdateVisible(false);
            }
        },
    }
</script>

<style lang="scss">
    .frame-content__background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .chat-container {
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        box-shadow: none;
        border-radius: 0;
        display: flex;
        z-index: 999999;

        @media (min-width: 380px) {
            right: 30px;
            bottom: 110px;
            min-width: 340px;
            max-width: 340px;
            max-height: 600px;
            height: 80vh;
            box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.12);
            border-radius: 8px;
        }
    }

    .chat {
        display: flex;
        flex-direction: column;
        background-color: #f5f5f7;
        border-radius: 0;
        overflow: hidden;
        position: relative;
        width: 100%;

        @media (min-width: 380px) {
            border-radius: 8px;
        }

        .chat__close-button {
            display: block;
            position: absolute;
            right: 10px;
            top: 10px;
            width: 30px;
            height: 30px;
            padding: 8px;
            color: #ffffff;
            cursor: pointer;
            outline: 0;
            background-color: transparent;
            z-index: 1;

            .svg-icon {
                width: 100%;
                height: 100%;
            }

            @media (min-width: 380px) {
                display: none;
            }
        }

        &.chat--sent {
            .chat__header {
                padding-bottom: 80px;
            }

            .chat__body {
                padding-top: 105px;
            }
        }
    }

    .chat__header {
        padding: 35px 30px 85px;
        color: #ffffff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: padding .25s cubic-bezier(.4,0,.2,1);

        .chat__title {
            font-size: 30px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;

            .svg-icon {
                width: 26px;
                height: 24px;
                margin-right: 17px;
            }
        }

        .chat__sub-title {
            font-size: 14px;
            text-align: left;
            line-height: 1.41;
        }
    }

    .chat__body {
        height: 100%;
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        padding-top: 148px;
        transition: padding .25s cubic-bezier(.4,0,.2,1);

        .message-info {
            display: flex;
            flex-direction: column;
            height: 100%;
            position: relative;

            .answer-container {
                background-color: #ffffff;
                border-radius: 5px;
                margin: 0 10px 25px;
                padding: 0;
                overflow: hidden;
                flex-shrink: 0;
                animation: fadeInUp .2s ease forwards 0s;

                .answer-title {
                    background-color: #e6edf5;
                    color: #3498db;
                    text-align: center;
                    padding: 15px;
                    font-weight: bold;
                    font-size: 14px;
                }

                .answer-message {
                    font-weight: 500;
                    font-size: 14px;
                    padding: 15px 20px;
                    line-height: 1.41;
                }
            }

            .input-message-container {
                flex: 1;
                height: 100%;
                background-color: #ffffff;
                padding: 40px 10px 0;
                min-height: 200px;

                & > div {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .input-message {
                    font-weight: 500;
                    font-size: 14px;
                    padding: 20px 20px 40px;
                    margin-bottom: 30px;
                    line-height: 1.41;
                    background-color: #f5f5f7;
                    border-radius: 24px 24px 3px 24px;
                    position: relative;
                    width: 82%;
                    align-self: flex-end;
                    word-break: break-word;
                    animation: fadeInRight .2s ease forwards 0s;

                    .input-message-phone {
                        color: #929497;
                        margin-bottom: 15px;
                    }

                    .svg-icon {
                        position: absolute;
                        bottom: 12px;
                        right: 10px;
                        width: 13px;
                        height: 11px;
                        color: #09cac0;
                    }
                }
            }
        }
    }

    .chat__send-button-container {
        width: 100%;
        background-color: #ffffff;
        padding: 0 15px;

        & > div {
            padding-top: 20px;
            border-top: 1px solid #cccccc;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .send-hint {
            padding: 15px;
            text-align: center;
            color: #72808e;
            font-size: 10px;
            font-weight: 500;
            max-width: 270px;
            line-height: 1.25;
        }
    }

    .chat__footer {
        padding: 7px 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-family: 'SFUIText', sans-serif;
        font-weight: normal;
        font-style: italic;
        font-size: 10px;
        color: #c9c9c9;
        background-color: #ffffff;
        border-top: 1px solid #cccccc;

        .svg-icon {
            height: 21px;
            width: 101px;
            margin-left: 6px;
        }
    }

</style>
