<template>
    <form class="send-form" novalidate>
        <div class="send-form__top">
            <AppInput v-model="name" label="Name" :error="chatFormName.error" :display-valid-icon="true" :maxlength="255"></AppInput>
            <AppInput v-model="phone" label="Phone" type="tel" :error="chatFormPhone.error" :display-valid-icon="true" :maxlength="50"></AppInput>
        </div>
        <div v-if="!!chatSentError" class="sent-error">{{chatSentError}}</div>
        <div class="send-form__bottom">
            <AppInput v-model="message" label="" type="textarea" :error="chatFormMessage.error" placeholder="Compose your message..." :maxlength="5000"></AppInput>
        </div>
    </form>
</template>

<script>
    import AppInput from '@/serviceChatWidget/js/components/AppInput';
    import * as chatStoreTypes from '@/serviceChatWidget/js/store/modules/chat/chatStoreTypes';
    import { mapMutations, mapState } from 'vuex';

    export default {
        name: "SendForm",

        components: {
            AppInput
        },

        computed: {
            ...mapState(chatStoreTypes.STORE_PATH, {
                chatFormName: state => state.form.name,
                chatFormPhone: state => state.form.phone,
                chatFormDisplayPhone: state => state.form.displayPhone,
                chatFormMessage: state => state.form.message,
                chatSentError: state => state.sentError,
            }),

            name: {
                get() {
                    return this.chatFormName.value;
                },
                set(value) {
                    this.chatUpdateFormName({
                        value: value,
                        error: this.validateNameValue(value),
                        touched: true
                    });
                }
            },
            phone: {
                get() {
                    return this.chatFormDisplayPhone;
                },
                set(value) {
                    let val = value;
                    if (!!value && !!this.chatFormDisplayPhone && this.chatFormDisplayPhone.indexOf('x') !== -1) {
                        val = value.slice(this.chatFormDisplayPhone.length);
                    }

                    this.chatUpdateFormPhone({
                        value: val,
                        error: this.validatePhoneValue(val),
                        touched: true
                    });
                }
            },
            message: {
                get() {
                    return this.chatFormMessage.value;
                },
                set(value) {
                    this.chatUpdateFormMessage({
                        value: value,
                        error: this.validateMessageValue(value),
                        touched: true
                    });
                }
            },
        },

        methods: {
            ...mapMutations(chatStoreTypes.STORE_PATH, {
                chatUpdateFormName: chatStoreTypes.MUTATION_UPDATE_FORM_NAME,
                chatUpdateFormPhone: chatStoreTypes.MUTATION_UPDATE_FORM_PHONE,
                chatUpdateFormMessage: chatStoreTypes.MUTATION_UPDATE_FORM_MESSAGE,
            }),

            validateNameValue(value) {
                let error = null;
                if (!this.validateRequired(value)) {
                    error = 'Required';
                }
                return error;
            },

            validatePhoneValue(value) {
                let error = null;
                if (!this.validateRequired(value)) {
                    error = 'Required';
                } else {
                    if (!this.validatePhone(value)) {
                        error = 'Invalid';
                    }
                }
                return error;
            },

            validateMessageValue(value) {
                let error = null;
                if (!this.validateRequired(value)) {
                    error = 'Required';
                }
                return error;
            },

            validateRequired(value) {
                return !!value && value.trim() !== '';
            },

            validatePhone(value) {
                let val = value.replace(/[^0-9+]/g, '');
                return /^([2-9][0-9]{9})$/.test(val);
            },
        },
    }
</script>

<style lang="scss">
    .send-form {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;

        .text-input {
            .text-input__input, .text-input__textarea {
                font-weight: 500;
            }

            &.text-input--valid {
                .text-input__input {
                    padding-right: 22px;
                }
            }

            &.text-input--has-error {
                .text-input__input {
                    padding-right: 45px;
                }
            }
        }

        .send-form__top {
            background-color: #ffffff;
            border-radius: 5px;
            margin: 0 10px 25px;
            padding: 6px 15px 8px;
            flex-shrink: 0;

            .text-input {
                border-bottom: 1px solid #cccccc;
                padding-bottom: 8px;
                margin-bottom: 0;

                &:last-child {
                    border-bottom: 0;
                    margin-bottom: 0;
                    margin-top: 22px;
                    padding-bottom: 0;
                }
            }
        }

        .sent-error {
            color: #EE4036;
            margin-bottom: 15px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
        }

        .send-form__bottom {
            flex: 1;
            height: 100%;
            background-color: #ffffff;
            padding: 12px 15px 0;
            min-height: 80px;

            .text-input {
                margin: 0;
                padding: 0 0 6px 0;
                height: 100%;

                .text-input__error {
                    top: 7px;
                }
            }

            .text-input__textarea {
                height: 100%;
            }
        }
    }
</style>
