<template>
    <component is="style">
        .chat__send-button-container .send-button {
            background-color: {{accentColor}};
        }
    </component>
</template>

<script>
    export default {
        name: "ChatFrameStyles",

        props: {
            accentColor: String,
        }
    }
</script>

<style>

</style>
