<template>
    <div class="chat-button" :class="{'chat-button--opened': opened}">
        <component is="style">
            .chat-button__button {
                background-color: {{accentColor}}
            }
        </component>

        <button class="chat-button__button" @click="$emit('click', $event)">
            <SvgIcon name="icon-service-chat" class="chat-button__open-icon"></SvgIcon>
            <SvgIcon name="icon-service-chat-close" class="chat-button__close-icon"></SvgIcon>
        </button>
    </div>
</template>

<script>
    import SvgIcon from '../SvgIcon';

    export default {
        name: "ChatButton",

        components: {
            SvgIcon
        },

        props: {
            accentColor: String,
            opened: {
                type: Boolean,
                default: false,
            }
        }
    }
</script>

<style lang="scss">
    .chat-button {
        &__button {
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 30px 8px 30px 30px;
            box-sizing: border-box;
            color: #fff;
            cursor: pointer;
            outline: 0;
            overflow: hidden;
            position: relative;
            transition: border-radius .25s cubic-bezier(.4,0,.2,1);
        }

        .svg-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100%;

            &.chat-button__open-icon {
                width: 26px;
                opacity: 1;
                transition: transform .25s cubic-bezier(.4,0,.2,1), opacity .25s cubic-bezier(.4,0,.2,1);
                transform: translate(-50%,-50%) rotate(0);
                transform-origin: 50% 50%;
            }

            &.chat-button__close-icon {
                width: 20px;
                opacity: 0;
                transition: transform .25s cubic-bezier(.4,0,.2,1), opacity .25s cubic-bezier(.4,0,.2,1);
                transform: translate(-50%,-50%) rotate(-180deg);
                transform-origin: 50% 50%;
            }
        }

        &.chat-button--opened {
            .chat-button__button {
                border-radius: 30px;
            }

            .svg-icon {
                &.chat-button__open-icon {
                    opacity: 0;
                    transform: translate(-50%,-50%) rotate(180deg);
                }

                &.chat-button__close-icon {
                    opacity: 1;
                    transform: translate(-50%,-50%) rotate(0);
                }
            }
        }
    }
</style>
