<template>
    <div id="service-chat-widget">
        <IFrame
            v-if="configAccentColor"
            style="position: fixed; right: 0; left: unset; bottom: 0; top: unset; width: 100%; height: 100%; z-index: 2000000002; margin: 0; padding: 0; border: 0; background-color: transparent !important; background-image: none !important; display: block !important;"
            scrolling="no"
            :style="`max-width: ${chatVisible ? '100%' : '0'} !important; max-height: ${chatVisible ? '100%' : '0'} !important;`"
            :base-url="baseUrl"
            :stylesheets="[
                'assets/service-chat-widget/css/core.css'
            ]"
        >
            <ChatFrameContent></ChatFrameContent>
        </IFrame>

        <IFrame
            v-if="configAccentColor"
            style="position: fixed; right: 0; left: unset; bottom: 0; top: unset; width: 100%; height: 100%; z-index: 2000000001; margin: 0; padding: 0; border: 0; background-color: transparent !important; background-image: none !important; display: block !important; max-width: 100px !important; max-height: 100px !important;"
            scrolling="no"
            :base-url="baseUrl"
            :stylesheets="[
                'assets/service-chat-widget/css/core.css'
            ]"
        >
            <ChatButtonFrameContent></ChatButtonFrameContent>
        </IFrame>
    </div>
</template>

<script>
    import ChatFrameContent from '@/serviceChatWidget/js/components/chatFrame/ChatFrameContent';
    import { mapState, mapActions } from 'vuex';
    import ChatButtonFrameContent from './chatButtonFrame/ChatButtonFrameContent';
    import IFrame from './IFrame';
    import * as chatStoreTypes from '@/serviceChatWidget/js/store/modules/chat/chatStoreTypes';
    import * as configStoreTypes from '@/serviceChatWidget/js/store/modules/config/configStoreTypes';

    export default {
        name: "App",

        components: {
            ChatFrameContent,
            ChatButtonFrameContent,
            IFrame
        },

        props: {
            baseUrl: String,
            apiToken: String,
        },

        computed: {
            ...mapState(chatStoreTypes.STORE_PATH, {
                chatVisible: state => state.visible,
            }),
            ...mapState(configStoreTypes.STORE_PATH, {
                configAccentColor: state => state.accentColor,
            }),
        },

        methods: {
            ...mapActions(configStoreTypes.STORE_PATH, {
                configGetWidgetData: configStoreTypes.ACTION_GET_WIDGET_DATA
            }),
        },

        created() {
            this.configGetWidgetData();
        }
    }
</script>

<style lang="scss">

</style>
