export const STORE_PATH = 'chat';

export const MUTATION_UPDATE_VISIBLE = 'updateVisible';
export const MUTATION_UPDATE_FORM_NAME = 'updateFormName';
export const MUTATION_UPDATE_FORM_PHONE = 'updateFormPhone';
export const MUTATION_UPDATE_FORM_DISPLAY_PHONE = 'updateFormDisplayPhone';
export const MUTATION_UPDATE_FORM_MESSAGE = 'updateFormMessage';
export const MUTATION_UPDATE_FORM_VALID = 'updateFormValid';
export const MUTATION_UPDATE_SENDING = 'updateSending';
export const MUTATION_UPDATE_SENT = 'updateSent';
export const MUTATION_UPDATE_SENT_DATA = 'updateSentData';
export const MUTATION_UPDATE_SENT_ERROR = 'updateSentError';

export const ACTION_SEND = 'send';
