import Vue from 'vue'
import Vuex from 'vuex'
import config from './modules/config/config';
import chat from './modules/chat/chat';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  strict: debug,
  modules: {
    config,
    chat,
  },
});
