<template>
    <div
        class="text-input"
        :class="{
            'text-input--focus': focused,
            'text-input--empty': isEmpty,
            'text-input--valid': isValid,
            'text-input--has-error': !!error,
        }"
    >
        <label v-if="label">{{label}}</label>
        <input
            v-if="type !== 'textarea'"
            class="text-input__input"
            v-bind="$attrs"
            :type="type"
            :autocomplete="autocomplete"
            ref="input"
            @input="handleInput"
            @change="handleChange"
            @focus="handleFocus"
            @blur="handleBlur"
        >
        <textarea
            v-else
            class="text-input__textarea"
            @input="handleInput"
            v-bind="$attrs"
            :autocomplete="autocomplete"
            :style="textareaCalcStyle"
            ref="textarea"
            @input="handleInput"
            @change="handleChange"
            @focus="handleFocus"
            @blur="handleBlur"
        ></textarea>
        <div v-if="!!error" class="text-input__error">{{error}}</div>
        <div v-if="isValid && displayValidIcon" class="text-input__valid-icon">
            <SvgIcon name="icon-service-chat-ok"></SvgIcon>
        </div>
    </div>
</template>

<script>
    import SvgIcon from '@/serviceChatWidget/js/components/SvgIcon';
    import calcTextareaHeight from '@/serviceChatWidget/js/helpers/calcTextareaHeight';

    export default {
        name: "AppInput",

        components: {SvgIcon},

        inheritAttrs: false,

        props: {
            value: [String, Number],
            type: {
                type: String,
                default: 'text'
            },
            autocomplete: {
                type: String,
                default: 'off'
            },
            autosize: {
                type: [Boolean, Object],
                default: false
            },
            label: String,
            error: String,
            displayValidIcon: {
              type: Boolean,
              default: false
            },
        },

        data() {
            return {
                textareaCalcStyle: {},
                focused: false,
            };
        },

        computed: {
            nativeInputValue() {
                return this.value === null || this.value === undefined ? '' : String(this.value);
            },

            isEmpty() {
                return this.nativeInputValue === '';
            },

            isValid() {
              return !this.error && !this.isEmpty;
            }
        },

        watch: {
            value(val) {
                this.$nextTick(this.resizeTextarea);
            },

            nativeInputValue() {
                this.setNativeInputValue();
            },

            type() {
                this.$nextTick(() => {
                    this.setNativeInputValue();
                    this.resizeTextarea();
                });
            }
        },

        methods: {
            setNativeInputValue() {
                const input = this.getInput();
                if (!input) return;
                if (input.value === this.nativeInputValue) return;
                input.value = this.nativeInputValue;
            },

            handleInput(event) {
                if (event.target.value === this.nativeInputValue) return;

                this.$emit('input', event.target.value);
                this.$nextTick(this.setNativeInputValue);
            },

            handleChange(event) {
                this.$emit('change', event.target.value);
            },

            getInput() {
                return this.$refs.input || this.$refs.textarea;
            },

            handleBlur(event) {
                this.focused = false;
                this.$emit('blur', event);
            },

            handleFocus(event) {
                this.focused = true;
                this.$emit('focus', event);
            },

            resizeTextarea() {
                const { autosize, type } = this;
                if (type !== 'textarea') return;
                if (!autosize) {
                    this.textareaCalcStyle = {
                        minHeight: calcTextareaHeight(this.$refs.textarea).minHeight,
                    };
                    return;
                }
                const minRows = autosize.minRows;
                const maxRows = autosize.maxRows;

                this.textareaCalcStyle = {
                  overflow: 'hidden',
                  ...calcTextareaHeight(this.$refs.textarea, minRows, maxRows)
                };
            },
        },

        mounted() {
            this.setNativeInputValue();
            this.resizeTextarea();
        },
    }
</script>

<style lang="scss">
    @import "../../scss/variables";

    .text-input {
        position: relative;
        margin: 16px 0;

        .text-input__input, .text-input__textarea {
            resize: none;
            border: 0;
            color: $mainFontColor;
            font-size: 14px;
            outline: 0;
            position: relative;
            width: 100%;
            z-index: 1;
            line-height: 20px;
            font-family: $mainFont;
            background-color: transparent;

            &::placeholder {
                font-size: 13px;
                font-weight: 400;
                color: #929497;
            }
        }

        .text-input__input {
            height: 30px;
            padding: 5px 0;
        }

        .text-input__textarea {
            padding: 2px 0 8px;
        }

        label {
            color: #929497;
            font-size: 13px;
            font-weight: 400;
            left: 0;
            line-height: 16px;
            pointer-events: none;
            position: absolute;
            top: 0.1em;
            transition: .2s ease;
            white-space: nowrap;
            z-index: 2;
        }

        .text-input__error {
            color: #ee4036;
            font-size: 12px;
            line-height: 1;
            position: absolute;
            top: 3px;
            right: 0;
            font-weight: 600;
        }

        .text-input__valid-icon {
            position: absolute;
            top: 9px;
            right: 0;
            width: 13px;
            height: 11px;
            color: #09cac0;
        }

        &.text-input--focus, &:not(.text-input--empty) {
            label {
                transform: translate(-10%, -80%) scale(0.8);
                font-weight: 600;
                color: #333333;
            }

            &.text-input--valid {
                label {
                    color: #09CAC0;
                }
            }
        }

        &.text-input--valid {
            label {
                color: #09CAC0;
            }
        }

        &.text-input--focus {

        }
    }
</style>
