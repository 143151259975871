import axios from 'axios';

// create a new axios instance
const instance = axios.create();
let _apiToken;

instance.interceptors.request.use(config => {
  if (!!config.data) {
    config.data['apiToken'] = _apiToken;
  }
  
  return config;
});

instance.interceptors.response.use(response => {
  return response;
}, (error) => {
  let message = 'Unexpected error';
  if ((error.response instanceof Object) && (error.response.data instanceof Object) && error.response.data.hasOwnProperty('message')) {
    message = error.response.data.message;
  }
  console.error('[ServiceChat.ai] Error:', message);

  return Promise.reject(error);
});

export function initHttp(baseUrl, apiToken) {
  instance.defaults.baseURL = baseUrl;
  _apiToken = apiToken;
}

export default instance;
